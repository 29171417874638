import { Box, Button, Grid, Group, TextInput, Textarea, NumberInput } from "@mantine/core";
import { IconCalendar, IconCheck, IconChevronDown, IconX } from "@tabler/icons-react";
import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import { AllLeagues, useUpdateLeague } from "../../graphql";
import { Notyf } from "notyf";
import { DateInput } from "@mantine/dates";
import dayjs from "dayjs";

const { Col } = Grid;

type Props = {
  setSelectedData?: (id: string) => void;
  data?: any;
} & ModalProps;

export const UpdateLeague = ({ data, ...props }: Props) => {
  const [updateLeague] = useUpdateLeague();
  const { getInputProps, reset, onSubmit, setValues } = useForm({
    initialValues: {
      name: "",
      description: "",
      numberTeams: 0,
      numberGroups: 0,
      startDate: new Date(), // Default to today
      expiryDate: new Date(), // Default to today
      inscriptionStartDate: new Date(), // Default to today
      inscriptionExpiryDate: new Date(), // Default to today
    },
  });

  useEffect(() => {
    if (data !== null && props.opened) {
      setValues({
        name: data.name,
        description: data.description,
        numberTeams: data.numberTeams,
        numberGroups: data.numberGroups,
        startDate: data.startDate ? new Date(data.startDate) : new Date(),
        expiryDate: data.expiryDate ? new Date(data.expiryDate) : new Date(),
        inscriptionStartDate: data.inscriptionStartDate
          ? new Date(data.inscriptionStartDate)
          : new Date(),
        inscriptionExpiryDate: data.inscriptionExpiryDate
          ? new Date(data.inscriptionExpiryDate)
          : new Date(),
      });
    }
  }, [data, props.opened]);

  const onFormSubmit = ({
    name,
    numberTeams,
    numberGroups,
    description,
    startDate,
    expiryDate,
    inscriptionStartDate,
    inscriptionExpiryDate,
  }: any) => {
    const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

    updateLeague({
      variables: {
        id: data.id,
        content: {
          name,
          numberTeams,
          numberGroups,
          description,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          expiryDate: dayjs(expiryDate).format("YYYY-MM-DD"),
          inscriptionStartDate: dayjs(inscriptionStartDate).format("YYYY-MM-DD"),
          inscriptionExpiryDate: dayjs(inscriptionExpiryDate).format("YYYY-MM-DD"),
        },
      },
      refetchQueries: [AllLeagues],
      onCompleted: () => {
        closeModal();
        notyf.success("تم تعديل الدورة");
      },
      onError: ({ graphQLErrors }) => {
        console.error("Error updating league:", graphQLErrors);
      },
    });
  };

  const closeModal = () => {
    props.onClose();
    reset();
  };

  return (
    <Modal
      {...props}
      onClose={closeModal}
      footer={
        <Box py={16} px={20} bg="slate.0">
          <Group justify={"left"} gap={"xs"}>
            <Button
              variant="outline"
              rightSection={<IconX size={15} />}
              bg="white"
              onClick={closeModal}
            >
              إلغاء
            </Button>
            <Button
              rightSection={<IconCheck size={15} />}
              type="submit"
              form="submit_form"
            >
              تأكيد
            </Button>
          </Group>
        </Box>
      }
    >
      <Box style={({ colors }) => ({ padding: 20 })}>
        <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
          <Grid gutter={20}>
            <Col span={12}>
              <TextInput
                placeholder="اسم الدورة"
                label="اسم الدورة"
                withAsterisk
                {...getInputProps("name")}
              />
            </Col>
            <Col span={12}>
              <Textarea
                placeholder="الوصف"
                label="الوصف"
                withAsterisk
                {...getInputProps("description")}
              />
            </Col>
            <Col span={6}>
              <NumberInput
                placeholder="عدد الفرق"
                label="عدد الفرق"
                withAsterisk
                {...getInputProps("numberTeams")}
              />
            </Col>
            <Col span={6}>
              <NumberInput
                placeholder="عدد المجموعات"
                label="عدد المجموعات"
                withAsterisk
                {...getInputProps("numberGroups")}
              />
            </Col>
            <Col span={6}>
              <DateInput
                placeholder="تاريخ البداية"
                label="تاريخ البداية"
                valueFormat={"YYYY-MM-DD"}
                withAsterisk
                {...getInputProps("startDate")}
              />
            </Col>
            <Col span={6}>
              <DateInput
                placeholder="تاريخ النهاية"
                label="تاريخ النهاية"
                valueFormat={"YYYY-MM-DD"}
                withAsterisk
                {...getInputProps("expiryDate")}
              />
            </Col>
            <Col span={6}>
              <DateInput
                placeholder="تاريخ بداية التسجيلات"
                label="تاريخ بداية التسجيلات"
                valueFormat={"YYYY-MM-DD"}
                withAsterisk
                {...getInputProps("inscriptionStartDate")}
              />
            </Col>
            <Col span={6}>
              <DateInput
                placeholder="تاريخ نهاية التسجيلات"
                label="تاريخ نهاية التسجيلات"
                valueFormat={"YYYY-MM-DD"}
                withAsterisk
                {...getInputProps("inscriptionExpiryDate")}
              />
            </Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
