import { Box, Button, Grid, Group, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { AllLeagues, useDeleteParticipatingTeams } from "../../graphql";
import useStore from "../../store/useStore";
import { Notyf } from "notyf";

const { Col } = Grid;

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    zIndex?: number; // Allow customization of zIndex
} & ModalProps;

export const DeleteTeam = ({ data, zIndex = 1050, ...props }: Props) => {
    const userData = useStore((state: any) => state.userData);
    console.log("data DeleteTeam:", data);

    const [deleteTeam] = useDeleteParticipatingTeams();

    const onFormSubmit = () => {
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

        deleteTeam({
            variables: {
                id: data,
            },
            refetchQueries: [AllLeagues],
            onCompleted: () => {
                closeModal();
                notyf.success("تم حذف الفريق");
            },
            onError: ({ graphQLErrors }) => {
                notyf.error("حدث خطأ أثناء الحذف");
                console.error(graphQLErrors);
            },
        });
    };

    const closeModal = () => {
        props.onClose();
    };

    return (
        <Modal
            {...props}
            zIndex={zIndex} // Pass zIndex for stacking
            onClose={closeModal}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"left"} gap={"xs"}>
                        <Button
                            variant="outline"
                            rightSection={<IconX size={15} />}
                            bg="white"
                            onClick={closeModal}
                        >
                            إلغاء
                        </Button>
                        <Button rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>
                            تأكيد
                        </Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({ padding: 20 })}>
                <Grid gutter={20}>
                    <Col span={12}>
                        <Text size={"md"} ta={"center"} mb={10}>
                            هل انت متأكد من حذف الفريق؟
                        </Text>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};
