import {
    ActionIcon,
    Avatar,
    Box,
    Divider,
    Flex,
    Menu,
    Text,
    Tooltip,
    Badge,
    Space,
} from "@mantine/core";
import {
    IconDotsVertical,
    IconEdit,
    IconTrash,
    IconInfoCircle,
    IconPlus,
    IconPrinter
} from '@tabler/icons-react';
import React, { useState } from "react";
import dayjs from "dayjs";

type Props = {
    data: any;
    onEditModal: (data:any) => void;
    onDeleteModal: (data:any) => void;
    onShowMatchesModal: (data:any) => void;
    onShowGroupsModal: (data:any) => void;
    onAddMatchModal: (data:any) => void;
    onAddTeamModal: (data:any) => void;
    hasPermission: (permission: string) => boolean;
    setSelectedData: (id: string) => void;
    setOpenGenerateMatchModal: (data:any) => void;
};

export const LeagueCard = ({
    data,
    onEditModal,
    onDeleteModal,
    onShowMatchesModal,
    onShowGroupsModal,
    onAddMatchModal,
    onAddTeamModal,
    hasPermission,
    setSelectedData,
    setOpenGenerateMatchModal
}: Props) => {
    const [openCardOptionMenu, setOpenCardOptionMenu] = useState<boolean>(false);
    console.log("data nami:",data)
    return (
        <Box
            p={16}
            bg="white"
            mb={15}
            style={({ colors}) => ({
                borderRadius: 4,
                outline: "1px solid " + colors.gray[3],
            })}
        >
            <Flex direction="column">
                {/* Top Section */}
                <Flex justify="space-between" align="center" w="100%">
                    <Flex gap="10px" align="center">

                        <Flex direction="column" style={{ textAlign: "right", gap: "8px" }}>
                            <Text size="md" color="gray.6" style={{ fontWeight: 500 }}>
                                اسم الدورة: <Text span>{data?.name}</Text>
                            </Text>
                            <Text size="sm" color="gray.5" style={{ fontWeight: 500 }}>
                                الوصف: <Text span>{data?.description}</Text>
                            </Text>
                            <Text size="xs" color="gray.5" style={{ fontWeight: 500 }}>
                                عدد فرق البطولة: <Text span>{data?.numberTeams}</Text>
                            </Text>
                            <Text size="xs" color="gray.4" style={{ fontWeight: 500 }}>
                                حالة التسجيل:
                                <Text
                                    span
                                    size="xs"
                                    color={dayjs(data?.inscriptionExpiryDate).isAfter(dayjs()) ? "green" : "red"}
                                    style={{ marginRight: "5px" }}
                                >
                                    {dayjs(data?.inscriptionExpiryDate).isAfter(dayjs()) ? "مفتوح" : "متوقف"}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                    <Menu
                        withArrow
                        shadow="md"
                        opened={openCardOptionMenu}
                        onOpen={() => setOpenCardOptionMenu(true)}
                        onClose={() => setOpenCardOptionMenu(false)}
                        closeOnClickOutside

                    >
                        <Menu.Target>
                            <ActionIcon variant={"light"} color={"darck"}>
                                <IconDotsVertical size="16" color="gray" />
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            {hasPermission("edit") && (
                                <Menu.Item
                                   onClick={() => {
                                    setSelectedData(data)
                                    onEditModal(true)
                                }}
                                   leftSection={<IconEdit size={14} />}
                                >

                                    تعديل
                                </Menu.Item>
                            )}
                            {data.matchs && data.matchs.length > 0
                            ? ""
                            : <Menu.Item
                            leftSection={<IconInfoCircle size={14} />}
                            onClick={() => {
                                setSelectedData(data)
                                setOpenGenerateMatchModal(true)
                            }}
                        >برمجة المباريات </Menu.Item>
                        }
                            {hasPermission("viewMatches") && (
                                <Menu.Item
                                    onClick={() => {
                                        setSelectedData(data)
                                        onShowMatchesModal(true)
                                    }}
                                    leftSection={<IconInfoCircle size={14} />}
                                >


                                    عرض المباريات
                                </Menu.Item>
                            )}
                            {hasPermission("viewGroups") && (
                                <Menu.Item
                                    onClick={() => {
                                setSelectedData(data)
                                onShowGroupsModal(true)
                            }}
                                    leftSection={<IconInfoCircle size={14} />}
                                >

                                    عرض المجموعات
                                </Menu.Item>
                            )}
                            {hasPermission("addMatch") && (
                                <Menu.Item
                                  
                            onClick={() => {
                                setSelectedData(data)
                                onAddMatchModal(true)
                            }}
                                    leftSection={<IconPlus size={14} />}
                                >

                                    اضافة مباراة
                                </Menu.Item>
                            )}
                            {hasPermission("addTeam") && (
                                <Menu.Item
                                    onClick={() => {
                                        setSelectedData(data)
                                        onAddTeamModal(true)
                                    }}
                                    leftSection={<IconPlus size={14} />}
                                >

                                    اضافة فرق
                                </Menu.Item>
                            )}
                            <Divider />
                            {hasPermission("delete") && (
                                <Menu.Item
                                    color="red"
                                    onClick={() => {
                                        setSelectedData(data)
                                        onDeleteModal(true)
                                    }}
                                    leftSection={<IconTrash size={14} />}
                                >

                                    حذف
                                </Menu.Item>
                            )}
                        </Menu.Dropdown>
                    </Menu>
                </Flex>

                {/* Center Section */}
                {data?.participatingTeams?.length > 0 && (
                    <>
                        <Divider my={12} />
                        <Text size="sm" color="gray.5">
                            الفرق المشاركة: {data?.participatingTeams?.length}
                        </Text>
                    </>
                )}

                {/* Bottom Section */}
                <Divider my={12} />
                <Flex justify="space-between" align="center" mb={5}>
                    <Text size="sm" color="gray.5" style={{fontSize:"13px"}}>
                        البداية: {dayjs(data?.startDate).format("DD/MM/YYYY")}
                    </Text>
                    <Text size="sm" color="gray.5" style={{fontSize:"13px"}}>
                        النهاية: {dayjs(data?.expiryDate).format("DD/MM/YYYY")}
                    </Text>
                </Flex>

                
                <Flex justify="space-between" align="center">
                <Text size="sm" color="gray.5" style={{fontSize:"13px"}}>
                    بداية التسجيل: {dayjs(data?.inscriptionExpiryDate).format("DD/MM/YYYY")}
                </Text>
                <Text size="sm" color="gray.5" style={{fontSize:"13px"}}>
                    نهاية التسجيل: {dayjs(data?.expiryDate).format("DD/MM/YYYY")}
                </Text>
                </Flex>
            </Flex>
        </Box>
    );
};
