import {ActionIcon, Divider, Group, Menu, Tooltip,Badge} from '@mantine/core';
import {
    IconDotsVertical,
    IconEdit,
    IconTrash,
    IconInfoCircle,
    IconPlus,
    IconPrinter
} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { GiSoccerBall } from "react-icons/gi";
import { useMediaQuery } from "@mantine/hooks";
import {LeagueCard} from "../Card/index"
const customStyles: TableStyles = {
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
        },
    },
    table: {
        style: {
            minHeight: 380
        },
    }
};
const customStylesMobile: TableStyles = {
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
        },
    },
    table: {
        style: {
            minHeight: 380
        },
    },
    cells: {
        style: {
          // Default row style
          width:"100%"
          
        },
        
      },
      
    
};

//data

type Props = {
    setSelectedData: (id: string) => void;
    setOpenShowGroupsModal: (status: boolean) => void;
    setOpenShowMatchsModal: (status: boolean) => void;

    setOpenEditModal: (status: boolean) => void;
    setOpenDeleteModal: (status: boolean) => void;

    setOpenAddParticipatingModal: (status: boolean) => void;
    setOpenEditParticipatingModal: (status: boolean) => void;
    setOpenGenerateMatchModal: (status: boolean) => void;
    setOpenAddMatchModal: (status: boolean) => void;

    setOpenAddParticipatingPlayersModal: (status: boolean) => void;
    setOpenAddParticipatingTechnicalStaffModal: (status: boolean) => void;
    
    data?: any;
};

export const LeaguesTabel = ({data, setOpenGenerateMatchModal,setSelectedData, setOpenShowGroupsModal, setOpenShowMatchsModal, setOpenEditModal, setOpenDeleteModal, setOpenAddParticipatingModal, setOpenEditParticipatingModal, setOpenAddMatchModal, setOpenAddParticipatingPlayersModal, setOpenAddParticipatingTechnicalStaffModal}: Props) => {
    const isMobile = useMediaQuery("(max-width: 768px)");

    const columns = [
        {name: 'اسم الدورة', selector: (row: any, index: number) => row.name, width: "120px" },
        {name: 'الوصف', selector: (row: any) => row.description, width: "auto" },
        {name: 'عدد الفرق', selector: (row: any) => row.numberTeams, width: "100px" },
        {name: 'عدد المجموعة', selector: (row: any) => row.numberGroups, width: "130px" },
        {
            name: ' التسجيل',
            selector: (row: any) => {
                const today = dayjs(); // Get today's date
                const inscriptionExpiryDate = dayjs(row.inscriptionExpiryDate); // Parse the inscription expiry date

                return inscriptionExpiryDate.isAfter(today) ? <Badge fw={500} color="teal" style={{ marginRight: '8px' }}>مفتوح</Badge> :  <Badge fw={500} color="red" style={{ marginRight: '8px' }}>متوقف</Badge>;
            },
            width: "130px"
        },
        {name: 'تاريخ البداية', selector: (row: any) => row.startDate, width: "120px" },
        {name: 'تاريخ النهاية', selector: (row: any) => row.expiryDate, width: "120px" },
        
        {cell: (row: any) => (
            <Group wrap={"nowrap"} justify={"flex-end"} w={"100%"} gap={5}>
                {row.matchs && row.matchs.length > 0
                    ? <Tooltip label={"عرض المباريات"}>
                        <ActionIcon
                            variant={"transparent"} color={"darck"}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenShowMatchsModal(true)
                            }}
                        >
                            <GiSoccerBall size={"1.125rem"} />
                        </ActionIcon>
                    </Tooltip>
                    : null
                }

                {row.participatingTeams && row.participatingTeams.length > 0
                    ? <Tooltip label={"عرض المجموعات"}>
                        <ActionIcon
                            variant={"transparent"} color={"darck"}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenShowGroupsModal(true)
                            }}
                        >
                            <IconInfoCircle size={"1.125rem"} />
                        </ActionIcon>
                    </Tooltip>
                    : null
                }

                <Menu shadow="md" width={180}>
                    <Menu.Target>
                        <ActionIcon variant={"light"} color={"darck"}>
                            <IconDotsVertical size="1.125rem" />
                        </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                        {row.participatingTeams && row.participatingTeams.length > 0
                            ? <Menu.Item
                                leftSection={<IconInfoCircle size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenShowGroupsModal(true)
                                }}
                            >عرض المجموعات</Menu.Item>
                            : null
                        }

                        {row.matchs && row.matchs.length > 0
                            ? <Menu.Item
                                leftSection={<IconInfoCircle size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenShowMatchsModal(true)
                                }}
                            >عرض المباريات</Menu.Item>
                            : <Menu.Item
                            leftSection={<IconInfoCircle size={14} />}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenGenerateMatchModal(true)
                            }}
                        >برمجة المباريات </Menu.Item>
                        }

                        <Divider />

                        {row.participatingTeams && row.participatingTeams.length > 0
                            ? <Menu.Item
                                leftSection={<IconPlus size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenAddMatchModal(true)
                                }}
                            >اضافة مباراة</Menu.Item>
                            : null
                        }

                        {row.participatingTeams && row.participatingTeams.length > 0
                            ? <Menu.Item
                                leftSection={<IconEdit size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenEditParticipatingModal(true)
                                }}
                            >تعديل الفرق</Menu.Item>
                            : <Menu.Item
                                leftSection={<IconPlus size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenAddParticipatingModal(true)
                                }}
                            >اضافة فرق</Menu.Item>
                        }

                        {row.participatingTeams && row.participatingTeams.length > 0
                            ? <Menu.Item
                                leftSection={<IconPlus size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenAddParticipatingPlayersModal(true)
                                }}
                            >اضافة لاعبين</Menu.Item>
                            : null
                        }
                        
                        {row.participatingTeams && row.participatingTeams.length > 0
                            ? <Menu.Item
                                leftSection={<IconPlus size={14} />}
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenAddParticipatingTechnicalStaffModal(true)
                                }}
                            >اضافة جهاز فني</Menu.Item>
                            : null
                        }
                        <Divider />
                        
                        <Menu.Item
                            leftSection={<IconEdit size={14} />}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenEditModal(true)
                            }}
                        >تعديل</Menu.Item>

                        <Menu.Item
                            leftSection={<IconTrash size={14} />}
                            onClick={() => {
                                setSelectedData(row.id)
                                setOpenDeleteModal(true)
                            }}
                        >حذف</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>
        ), allowOverflow: true, button: true, width: '100px' }
    ];

 


    return isMobile ? (
        <div>
            {data?.map((row: any) => (
                <LeagueCard
                    key={row.id}
                    data={row}
                    onEditModal={setOpenEditModal}
                    onDeleteModal={setOpenDeleteModal}
                    onShowMatchesModal={setOpenShowMatchsModal}
                    onShowGroupsModal={setOpenShowGroupsModal}
                    onAddMatchModal={setOpenAddMatchModal}
                    onAddTeamModal={setOpenAddParticipatingModal}
                    setSelectedData = {setSelectedData}
                    setOpenGenerateMatchModal={setOpenGenerateMatchModal}
                    hasPermission={(permission: string) => true /* Adjust permission logic */}
                />
            ))}
        </div>
    ) : (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            pagination
            paginationComponentOptions={{
                rowsPerPageText: "الاسطر في كل صفحة",
                rangeSeparatorText: "من",
            }}
        />
    );
};

