import { MutationTuple, useMutation } from "@apollo/client";
import { GeneratMatches } from "../..";

interface VariableProps {
    leagueId: string;
    type: number; // 0 for single match, 1 for home and away
}

export const useGeneratMatches = (): MutationTuple<any, VariableProps> => {
    return useMutation<any, VariableProps>(GeneratMatches);
};
