import {Box, Divider, Grid, Group, useMantineTheme, Stack, Text, Menu, ActionIcon,Button} from "@mantine/core";
import {IconDotsVertical, IconEdit, IconPlus, IconTrash,IconArrowBigLeft,IconInfoCircle,IconPrinter } from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import useStore from "../../store/useStore";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
const {Col} = Grid

type Props = {
    setSelectedData: (id: string) => void;
    data?: any;
    dataMatch: any;
    setOpenEditMatchModal: (status: boolean) => void;
    setOpenDeleteMatchModal: (status: boolean) => void;

    setOpenAddMatchResultModal: (status: boolean) => void;
    setOpenEditMatchResultModal: (status: boolean) => void;

    setOpenAddMatchCardModal: (status: boolean) => void;
    setOpenAddManOfMatchModal: (status: boolean) => void;
    setOpenEditManOfMatchModal: (status: boolean) => void;

    setOpenAddScorerModal: (status: boolean) => void;
    setOpenUpdateScorerModal: (status: boolean) => void;


    setOpenAddSub: (status: boolean) => void;
    setOpenEditStarter: (status: boolean) => void;
    setOpenAddParticipatingPlayersMatch: (status: boolean) => void;
    setOpenEditParticipatingPlayersMatch: (status: boolean) => void;

    setArbitreModal: (status: boolean) => void;
    setopenShowPlayerListModal: (status: boolean) => void;

} & ModalProps;
//setOpenAddSub setOpenEditStarter setOpenAddStarter
export const ShowMatch = ({data,dataMatch, setSelectedData, setOpenAddMatchResultModal,  setOpenEditMatchResultModal, setOpenEditMatchModal, setOpenDeleteMatchModal, setOpenAddMatchCardModal, setOpenAddManOfMatchModal, setOpenEditManOfMatchModal, setOpenAddScorerModal, setOpenUpdateScorerModal,setOpenAddSub, setOpenEditStarter, setOpenAddParticipatingPlayersMatch,setOpenEditParticipatingPlayersMatch,setArbitreModal,setopenShowPlayerListModal, ...props}: Props) => {
    const userData = useStore((state: any) => state.userData);
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const closeModal = () => {
        props.onClose();
    };
    useEffect(() => {
        console.log("data")
        console.log(data)
    }, [data, props.opened])

    return (
        <Modal
            {...props} onClose={closeModal}
            footer={<></>}
            size={"80%"}

            styles={{
                body: {
                    backgroundColor: theme.colors.gray[1]
                }
            }}
        >
            <Box style={({ colors }) => ({padding: isMobile?10:20})}>
                {data?.matchs?.length > 0
                    ? 
                    <>
                     
                    <Grid gutter={20}>
                        {data?.matchs?.map((item: any, index: number) => (
                            <Col key={index} span={isMobile?12:6} >
                                <Box bg={theme.white} style={({ colors }) => ({padding: isMobile?8:20})}>
                                    <Group justify={"space-between"} align="flex-start" wrap="nowrap"  style={{ flexDirection: isMobile?'column-reverse':"row" }}>
                                        <Stack gap={5} w={"100%"}>
                                            <Group justify={"space-around"} align="center" pb={5} className="tete-match">
                                            
                                                <Text  size={isMobile?"13px":"16px"} c={theme.colors.gray[5]}>{item?.firstTeam?.team?.name}</Text>
                                                <Text ta={"center"} size={isMobile?"13px":"16px"}  fw={"bold"} color={theme.colors.gray[7]}>ضد</Text>
                                                <Text size={isMobile?"13px":"16px"}  c={theme.colors.gray[5]}>{item?.secondTeam?.team?.name}</Text>
                                            </Group>

                                            <Group justify={"space-around"} align="center" pb={8}>
                                                <Stack gap={5}>
                                                    <Text dir={"rtl"} ta={"center"} size={isMobile?"12px":"14px"} color={theme.colors.gray[7]}>
                                                        {dayjs(item?.date).format("YYYY-MM-DD")}
                                                    </Text>
                                                    <Text dir={"rtl"} ta={"center"} size={"13px"} color={theme.colors.gray[7]}>
                                                        {dayjs(item?.date).format("HH:mm")}
                                                        </Text>
                                                </Stack>
                                            </Group>

                                            <Group justify={"space-around"} align="center">
                                                <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}>{item?.firstTeamGoal}</Text>
                                                <Text ta={"center"} size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>-</Text>
                                                <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}>{item?.secondTeamGoal}</Text>
                                            </Group>

                                            <Group justify={"space-between"} align="center" wrap="nowrap">
                                                <Stack gap={5} align="flex-start">
                                                    {item?.firstTeamScorersMatch?.length > 0 ?
                                                        item?.firstTeamScorersMatch?.map((item2: any) => (
                                                            <Text key={item2?.id} size={"12px"} c={theme.colors.gray[5]}>
                                                                {`${item2?.participating_player?.player?.person?.first_name} ${item2?.participating_player?.player?.person?.second_name} ${item2?.participating_player?.player?.person?.third_name} ${item2?.participating_player?.player?.person?.tribe}`}
                                                                {" د"} {item2?.time}
                                                            </Text>
                                                        ))
                                                        : null
                                                    }
                                                </Stack>
                                                <Text ta={"center"} size={"lg"} fw={"bold"} color={theme.colors.gray[7]}>-</Text>
                                                <Stack gap={5} align="flex-start">
                                                    {item?.secondTeamScorersMatch?.length > 0 ?
                                                        item?.secondTeamScorersMatch?.map((item2: any) => (
                                                            <Text key={item2?.id} size={"12px"} c={theme.colors.gray[5]}>
                                                                {`${item2?.participating_player?.player?.person?.first_name} ${item2?.participating_player?.player?.person?.second_name} ${item2?.participating_player?.player?.person?.third_name} ${item2?.participating_player?.player?.person?.tribe}`}
                                                                {" د"} {item2?.time}
                                                            </Text>
                                                        ))
                                                        : null
                                                    }
                                                </Stack>
                                            </Group>
                                        </Stack>
                                        <Stack h={"100%"} gap={0} justify="flex-start">
                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <ActionIcon variant={"transparent"} color={"gray"}>
                                                        <IconDotsVertical size="1rem" />
                                                    </ActionIcon>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    {(item?.createdAt === item?.updatedAt && item?.firstTeamGoal === 0 && item?.secondTeamGoal === 0)
                                                        ? <Menu.Item
                                                            leftSection={<IconPlus size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenAddMatchResultModal(true)
                                                            }}
                                                        >اضافة نتيجة المباراة</Menu.Item>
                                                        : <Menu.Item
                                                            leftSection={<IconEdit size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenEditMatchResultModal(true)
                                                            }}
                                                        >تعديل نتيجة المباراة</Menu.Item>
                                                    }

                                                    <Menu.Item
                                                        leftSection={<IconPlus size={14} />}
                                                        onClick={() => {
                                                            setSelectedData(item)
                                                            setOpenAddMatchCardModal(true)
                                                        }}
                                                    >اضافة بطاقات</Menu.Item>

                                                    

                                                    {(item?.manOfMatch === null || item?.manOfMatch === "")
                                                        ? <Menu.Item
                                                            leftSection={<IconPlus size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenAddManOfMatchModal(true)
                                                            }}
                                                        >اضافة رجل المباراة</Menu.Item>
                                                        : <Menu.Item
                                                            leftSection={<IconEdit size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenEditManOfMatchModal(true)
                                                            }}
                                                        >تعديل رجل المباراة</Menu.Item>
                                                    }

                                                    <Menu.Item
                                                        leftSection={<IconPlus size={14} />}
                                                        onClick={() => {
                                                            setSelectedData(item)
                                                            setOpenAddScorerModal(true)
                                                        }}
                                                    >اضافة هداف</Menu.Item>

                                                    {item?.firstTeamScorersMatch?.length > 0 || item?.secondTeamScorersMatch?.length > 0
                                                        ? <Menu.Item
                                                            leftSection={<IconEdit size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenUpdateScorerModal(true)
                                                            }}
                                                        >تعديل الهداف</Menu.Item>
                                                        : null
                                                    }

                                                    <Divider />

                                                    <Menu.Item
                                                            leftSection={<IconPlus size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenAddParticipatingPlayersMatch(true)
                                                            }}
                                                        >اضافة قائمة اللاعبين</Menu.Item>

                                                    <Menu.Item
                                                            leftSection={<IconEdit size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenEditParticipatingPlayersMatch(true)
                                                            }}
                                                        >تعديل على القائمة</Menu.Item>
                                                        
                                                        <Menu.Item
                                                            leftSection={<IconInfoCircle size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setopenShowPlayerListModal(true)
                                                            }}
                                                        > قائمة اللاعبين</Menu.Item>
                                                        
                                                        <Divider />
                                                        <Menu.Item
                                                            leftSection={<IconPlus size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setOpenAddSub(true)
                                                            }}
                                                        >اضافة تبديل</Menu.Item>
                                                    <Divider />
                                                    <Menu.Item
                                                            leftSection={<IconPlus size={14} />}
                                                            onClick={() => {
                                                                setSelectedData(item)
                                                                setArbitreModal(true)
                                                            }}
                                                        >اضافة الحكام</Menu.Item>

                                                        <Menu.Item
                                                            component={"a"} leftSection={<IconPrinter size={14} />}
                                                            href={`https://print.omkooora.com//#/match/${item?.id}`}
                                                            target={"_blank"}
                                                        >طباعة المباراة</Menu.Item>
                                                    <Divider />
                                                    
                                                    <Menu.Item
                                                        leftSection={<IconEdit size={14} />}
                                                        onClick={() => {
                                                            setSelectedData(item)
                                                            setOpenEditMatchModal(true)
                                                        }}
                                                    >تعديل</Menu.Item>

                                                    <Menu.Item
                                                        leftSection={<IconTrash size={14} />}
                                                        onClick={() => {
                                                            setSelectedData(item.id)
                                                            setOpenDeleteMatchModal(true)
                                                        }}
                                                    >حذف</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </Stack>
                                    </Group>
                                    
                                        <Stack justify= {"space-around"} gap={5} w={"100%"} style={{marginTop:"5%",display:"flex",flexDirection: "row"}}>

                                            
                                            <div style={{width:'100%',display:'flex',flexDirection:"column",alignItems:'center'}}> 
                                                <div> 
                                                حكام
                                                </div>
                                                <div style={{width:'85%',display:"flex",justifyContent:'space-between',marginTop:"9px",flexWrap:"wrap"}}> 
                                                <span> {item?.arbitre?.Arbitre1}</span><span> {item?.arbitre?.Arbitre2}</span><span> {item?.arbitre?.Arbitre3}</span><span> {item?.arbitre?.Arbitre4}</span>
                                                </div>
                                            </div>
                                            {/*item?.firstTeam?.participatingPlayers?.map((participatinPlayer: any, index: number) => (
                                                <div key={index} style={{direction: 'rtl'}}>
                                                    {participatinPlayer?.participatingPlayersMatches?.map((participatinplayerMatch: any, indexMatch: number) => (
                                                    <Group justify={"space-around"} align="center" wrap="nowrap">
                                                        {participatinplayerMatch?.sub}
                                                       { participatinplayerMatch?.starter === true ? (
                                                        <Group justify={"space-around"} align="center">
                                                        <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}>{participatinPlayer.number} - </Text>
                                                        <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} >{participatinPlayer?.player?.person?.first_name} </Text>
                                                        
                                                        { participatinplayerMatch?.sub === true ? (
                                                           <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}><IconArrowBigLeft color="#41b883" fill="#41b883" size={14} /></Text>
                                                        ) : null}
                                                        </Group>
                                                        ) : null}

                                                        
                                                    </Group>
                                                    ))}
                                                    
                                                </div>
                                            ))*/}
                                                                                            
                                            
                                            {/*<Text ta={"center"} size={"lg"} fw={"bold"} color={theme.colors.gray[7]}></Text>>*/}

                                           {/*<Group  align="center" wrap="nowrap" style={{flexDirection:"column", alignItems: "flex-start"}}>*/}
                                                
                                            {/*item?.secondTeam?.participatingPlayers?.map((participatinPlayer: any, index: number) => (
                                                <div key={index} style={{direction: 'rtl'}}>
                                                    {participatinPlayer?.participatingPlayersMatches?.map((participatinplayerMatch: any, indexMatch: number) => (
                                                    <Group justify={"space-around"} align="center" wrap="nowrap">
                                                        {participatinplayerMatch?.sub}
                                                       { participatinplayerMatch?.starter === true ? (
                                                        <Group justify={"space-around"} align="center">
                                                        <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}>{participatinPlayer.number} - </Text>
                                                        <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} >{participatinPlayer?.player?.person?.first_name} </Text>
                                                        
                                                        { participatinplayerMatch?.sub === true ? (
                                                           <Text size={"lg"} ta={"center"} c={theme.colors.gray[7]} fw={"bold"}><IconArrowBigLeft color="#41b883" fill="#41b883" size={14} /></Text>
                                                        ) : null}
                                                        </Group>
                                                        ) : null}

                                                        
                                                    </Group>
                                                    ))}
                                                    
                                                </div>
                                            ))
                                                    
                                            </Group>*/}
                                        </Stack>
                                        
                                    
                                </Box>
                            </Col>
                        ))}
                    </Grid>
                    </>
                    : ""
                }
            </Box>
            
        </Modal>
    );
};