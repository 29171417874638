import { Box, Button, Grid, Group, Select } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import React from "react";
import { useForm } from "@mantine/form";
import Modal, { Props as ModalProps } from "./Modal";
import { Notyf } from "notyf";
import { useGeneratMatches,AllLeagues } from "../../graphql";

type Props = {
  setSelectedData?: (id: string) => void;
  data?: any; // Expecting data to contain `id` (league ID)
} & ModalProps;

export const GenerateMatch = ({ data, ...props }: Props) => {
  const { getInputProps, reset, onSubmit } = useForm({
    initialValues: { matchType: "" }, // Default initial value
  });

  const [createMatches, { loading, error }] = useGeneratMatches();

  const onFormSubmit = async ({ matchType }: { matchType: string }) => {
    const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

    try {
      if (!data?.id) {
        notyf.error("League ID is missing.");
        return;
      }
      let typeINT = parseInt(matchType, 10)
      console.log("arseInt(matchType, 10)",typeINT)
     
      createMatches({
        variables: {
            leagueId: data.id,
            type: parseInt(matchType, 10), // Convert matchType to an integer
          },
                  refetchQueries: [AllLeagues],
                  onCompleted: () => {
                      closeModal();
                      notyf.success("تمت البرمجة");
                  },
                  onError: ({graphQLErrors}) => {
                      console.log(false)
                  }
              })

      
      //closeModal();
    } catch (err) {
      console.error("Error creating matches:", err);
      notyf.error("Failed to create matches. Please try again.");
    }
  };

  const closeModal = () => {
    props.onClose();
    reset();
  };

  return (
    <Modal
      {...props}
      onClose={closeModal}
      footer={
        <Box py={16} px={20} bg="slate.0">
          <Group justify={"left"} gap={"xs"}>
            <Button
              variant="outline"
              rightSection={<IconX size={15} />}
              bg="white"
              onClick={closeModal}
              disabled={loading}
            >
              إلغاء
            </Button>
            <Button
              rightSection={<IconCheck size={15} />}
              type="submit"
              form="submit_form"
              disabled={loading}
            >
              {loading ? "جاري التنفيذ..." : "تأكيد"}
            </Button>
          </Group>
        </Box>
      }
    >
      <Box style={({ colors }) => ({ padding: 20 })}>
        <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
          <Grid gutter={20}>
            <Grid.Col span={12}>
              <Select
                label="نوع المباريات"
                placeholder="اختر نوع المباراة"
                withAsterisk
                data={[
                  { value: "0", label: "ذهاب" },
                  { value: "1", label: "ذهاب وعودة" },
                ]}
                {...getInputProps("matchType")}
              />
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
